import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Toast } from 'react-bootstrap';

const Newproject = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    project_name: '',
    responsible: '',
    explanation: '',
    building_name: '',
    device_no: '',
    datum: ''
  });
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const updateProjectUrl = process.env.REACT_APP_NEW_PROJECT_URL;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/signin');
      return;
    }

    try {
      const response = await fetch(updateProjectUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.status === 401 || data.status_code === 401) {
        localStorage.removeItem('token');
        navigate('/signin');
      } else if (data.status === 'success') {
        setShowToast(true);
        setError('');
        setTimeout(() => {
          if (onSuccess) onSuccess(); // Kayıt başarılı olunca onSuccess callback'i çağrılır
        }, 2000);
      } else {
        setError(data.message);
        setShowToast(false);
      }
    } catch (err) {
      setError('Bir hata oluştu, lütfen tekrar deneyin.');
      setShowToast(false);
    }
  };
  
  return (
    <Container>
      <h2 className="text-center my-4">Yeni Proje</h2>
      {error && <p className="text-danger">{error}</p>}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="project_name">
              <Form.Label>Proje Adı</Form.Label>
              <Form.Control
                type="text"
                name="project_name"
                value={formData.project_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="responsible">
              <Form.Label>Sorumlusu</Form.Label>
              <Form.Control
                type="text"
                name="responsible"
                value={formData.responsible}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="building_name">
              <Form.Label>Bina Adı</Form.Label>
              <Form.Control
                type="text"
                name="building_name"
                value={formData.building_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="device_no">
              <Form.Label>Cihaz No</Form.Label>
              <Form.Control
                type="text"
                name="device_no"
                value={formData.device_no}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={12}>
            <Form.Group controlId="explanation">
              <Form.Label>Açıklama</Form.Label>
              <Form.Control
                as="textarea"
                name="explanation"
                value={formData.explanation}
                onChange={handleChange}
                rows={3}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Form.Group controlId="datum">
              <Form.Label>Tarih</Form.Label>
              <Form.Control
                type="date"
                name="datum"
                value={formData.datum}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-4">
          Proje Ekle
        </Button>
      </Form>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={2000}
        autohide
        className="mt-4"
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
      >
        <Toast.Header>
          <strong className="mr-auto">Başarılı</strong>
        </Toast.Header>
        <Toast.Body>Proje başarıyla eklendi!</Toast.Body>
      </Toast>
    </Container>
  );
};

export default Newproject;
