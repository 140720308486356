import React, { useEffect, useState } from 'react';
import { Accordion, ListGroup, Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';  // FaCheck ikonunu içe aktardık

const MainTransaction = ({ setActiveTab, maintainTransaction, setEditingYagmurSuyuHesabi }) => {
    const [categories, setCategories] = useState([]);
    const [showToast, setShowToast] = useState(false); // Pop-up durumu
    const [toastMessage, setToastMessage] = useState(''); // Pop-up mesajı
    const navigate = useNavigate();
    const getCategoryURL = process.env.REACT_APP_GET_CATEGORY_URL;
    const getTransactionDataURL = process.env.REACT_APP_GET_TRANSACTION_DATA_URL;

    // Kategorileri getirme
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(getCategoryURL, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({
                        project_id:  maintainTransaction.id, 
                    }),
                });

                const data = await response.json();

                if (response.status === 401) {
                    navigate('/signin');  // Eğer token geçersizse giriş sayfasına yönlendir
                } else if (data.success) {
                    setCategories(data.categories);  // Başarılı olursa kategorileri ayarla
                } else {
                    console.error('Kategori verileri alınamadı:', data.message);
                }
            } catch (error) {
                console.error('API hatası:', error);
            }
        };

        fetchCategories();
    }, [navigate, getCategoryURL,maintainTransaction]);

    // Alt kategorilere tıklanınca işlem
    const handleChildClick = async (child) => {
        try {
            const token = localStorage.getItem('token');
         
            const response = await fetch(getTransactionDataURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    project_id:  maintainTransaction.id,
                    page_name: child.page_name,
                }),
            });

            const data = await response.json();

            if (response.status === 401) {
                 navigate('/signin');
            } else if (response.status === 200 || response.status === 404) { 
                setEditingYagmurSuyuHesabi(data);
                setActiveTab('yagmursuyuhesabi');
            } else {
                setToastMessage(data.message);
                setShowToast(true);
                console.error('Veri bulunamadı:', data.message);
            }
        } catch (error) {
            console.error('API hatası:', error);
        }
    };

    // Kategori ağacını render etme
    const renderAccordion = (categories) => {
        return categories.map((category) => (
            <Accordion.Item eventKey={category.id} key={category.id}>
                <Accordion.Header>{category.category_name}</Accordion.Header>
                <Accordion.Body>
                    {category.children && category.children.length > 0 && (
                        <ListGroup variant="flush">
                            {category.children.map((child) => (
                                <ListGroup.Item
                                    key={child.id}
                                    action
                                    onClick={() => handleChildClick(child)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {child.category_name}
                                    {console.error(child.filled)}
                                    {child.filled === 'X' && <FaCheck className="text-success ms-2" />}  {/* Yeşil tik */}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                </Accordion.Body>
            </Accordion.Item>
        ));
    };

    return (
        <div className="container mt-4">
            <div className="card mb-4">
                <div className="card-header">
                    <h2 className="mb-0">Proje: {maintainTransaction.project_name}</h2>
                </div>
                <div className="card-body">
                    <p>İşlemler için {maintainTransaction.project_name} projesi seçildi.</p>
                </div>
            </div>

            <div className="card">
                <div className="card-header">
                    <h4>Kategoriler</h4>
                </div>
                <div className="card-body">
                    <Accordion defaultActiveKey="0">
                        {renderAccordion(Object.values(categories))}
                    </Accordion>
                </div>
            </div>

            {/* Toast pop-up */}
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{ position: 'absolute', top: 20, right: 20 }}
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </div>
    );
};

export default MainTransaction;
