import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button, Table, Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Yönlendirme için

const UsersGrid = () => {
  const [users, setUsers] = useState([]);
  const [emailFilter, setEmailFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showToast, setShowToast] = useState(false); // Pop-up durumu
  const [toastMessage, setToastMessage] = useState(''); // Pop-up mesajı
  const navigate = useNavigate();
  const getUsersUrl = process.env.REACT_APP_GET_USERS_URL; // URL buradan geliyor
  const updateUserStatusUrl = process.env.REACT_APP_UPDATE_USER_STATUS_URL; // URL buradan geliyor

  useEffect(() => {
    console.log("getUsersUrl: ", getUsersUrl);
  }, [getUsersUrl]);

  const fetchUsers = useCallback(async () => {
    const token = localStorage.getItem('token'); 
    if (!token) {
      navigate('/signin');
      return;
    }

    try {
      console.log('Fetching users...');

      const response = await fetch(`${getUsersUrl}?email=${emailFilter}&statusFilter=${statusFilter}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        console.log("Token expired or invalid, redirecting to signin.");
        navigate('/signin');
      } else if (!response.ok) {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      } else {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          console.log('Users fetched successfully:', data);
          setUsers(data.users);
        } else {
          console.error("Yanıt JSON formatında değil:", await response.text());
        }
      }
    } catch (error) {
      console.error("Veriler alınırken hata oluştu:", error);
    }
  }, [emailFilter, statusFilter, getUsersUrl, navigate]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleEditClick = (user) => {
    setEditingUserId(user.id);
    setSelectedStatus(user.user_status || '');
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSave = async (userId) => {
    const token = localStorage.getItem('token'); 

    try {
      const response = await fetch(updateUserStatusUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: userId,
          user_status: selectedStatus
        })
      });

      const data = await response.json();

      if (data.success) {
        setToastMessage('Kullanıcı durumu güncellendi.');
        setShowToast(true); // Pop-up mesajını göster
        fetchUsers();
        setEditingUserId(null);  // Düzenleme modundan çık
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Güncelleme sırasında hata oluştu", error);
    }
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Form.Check
            type="checkbox"
            label="Sadece onayda olanlar"
            checked={statusFilter}
            onChange={(e) => setStatusFilter(e.target.checked)}
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Email ile ara"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
          />
        </Col>
      </Row>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Firma Adı</th>
            <th>İsim</th>
            <th>Soyisim</th>
            <th>Email</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.firm_name}</td>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.email}</td>
              <td>
                {editingUserId === user.id ? (
                  <Form.Select value={selectedStatus} onChange={handleStatusChange}>
                    <option value="">Onay bekliyor</option>
                    <option value="X">Onaylandı</option>
                  </Form.Select>
                ) : (
                  user.user_status === 'X' ? 'Onaylandı' : 'Onay bekliyor'
                )}
              </td>
              <td>
                {editingUserId === user.id ? (
                  <Button onClick={() => handleSave(user.id)}>Kaydet</Button>
                ) : (
                  <Button onClick={() => handleEditClick(user)}>Değiştir</Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Toast pop-up */}
      <Toast 
        onClose={() => setShowToast(false)} 
        show={showToast} 
        delay={3000} 
        autohide 
        style={{ position: 'absolute', top: 20, right: 20 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default UsersGrid;
