import React, { useEffect, useState } from 'react'; 
import { Container, Card, Form, Button, Toast, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const YagmurSuyuHesabi = ({ editingYagmurSuyuHesabi, setActiveTab, maintainTransaction }) => {
    const [formData, setFormData] = useState({ 
        a1: editingYagmurSuyuHesabi.data?.a1 || 0,
        a2: editingYagmurSuyuHesabi.data?.a2 || 0.0,
        a3: editingYagmurSuyuHesabi.data?.a3 || 0,
        a4: editingYagmurSuyuHesabi.data?.a4 || 0.000,
        a5: editingYagmurSuyuHesabi.data?.a5 || '',
    });

    const getmYagmurSuyuHesabiURL = process.env.REACT_APP_M_YAGMUR_SUYU_HESABI_URL;

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [isUpdateMode, setIsUpdateMode] = useState(false); // Güncelle mi Kaydet mi kontrolü

    useEffect(() => { 
        setFormData({ 
            a1: editingYagmurSuyuHesabi.data?.a1 || 0,
            a2: editingYagmurSuyuHesabi.data?.a2 || 0.0,
            a3: editingYagmurSuyuHesabi.data?.a3 || 0,
            a4: editingYagmurSuyuHesabi.data?.a4 || 0.000,
            a5: editingYagmurSuyuHesabi.data?.a5 || '',
        });

        // Eğer a1 veya a3 doluysa güncelleme moduna geçiyoruz
        if (editingYagmurSuyuHesabi.data?.a1 || editingYagmurSuyuHesabi.data?.a3) {
            setIsUpdateMode(true);
        } else {
            setIsUpdateMode(false);
        }
    }, [editingYagmurSuyuHesabi]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === "a1") {
            const a2Value = value * 0.75;
            let a4Value = formData.a3 ? a2Value / formData.a3 : ''; 
            let a5Value = a4Value ? yagmurSuyuHesabi(a4Value) : ''; 
            
            setFormData({
                ...formData,
                [name]: value,
                a2: a2Value,
                a4: a4Value,
                a5: a5Value,
            });
        } else if (name === "a3") {
            const a2Value = formData.a1 * 0.75;
            let a4Value = value === "0" || value === "" ? '' : a2Value / value;
            let a5Value = a4Value ? yagmurSuyuHesabi(a4Value) : '';
    
            setFormData({
                ...formData,
                [name]: value,
                a4: a4Value,
                a5: a5Value,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleGoBack = () => {
        setActiveTab('maintransaction'); 
    };

    const handleSave = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        const projectId = maintainTransaction.id;

        // Zorunlu alan kontrolü
        if (!formData.a1 || !formData.a3) {
            setShowToast(true);
            setToastMessage("A1 ve A3 alanları zorunludur.");
            return;
        } 

        try {
            const response = await fetch(getmYagmurSuyuHesabiURL, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ ...formData, project_id: projectId }),
            });

            const result = await response.json();
            if (!response.ok) {
                // Hata durumunda hata kodlarına göre işlem yap
                switch (result.status_code) {
                    case 400:
                        //console.error('Hata 400: ' + result.message);
                       // alert('Geçersiz işlem. Lütfen tekrar deneyin.');
                        setShowToast(true);
                        setToastMessage(result.message); 
                        break;
                    case 401:
                       // console.error('Hata 401: ' + result.message);
                       // alert('Yetkisiz erişim. Lütfen giriş yapın.');
                        setShowToast(true);
                        //setToastMessage(result.message);
                        setTimeout(() => handleGoBack(), 1000); // 2 saniye sonra 
                        break;
                    case 500:
                        console.error('Hata 500: ' + result.message);
                        alert('Sunucu hatası. Lütfen daha sonra tekrar deneyin.');
                        setTimeout(() => handleGoBack(), 1000); // 2 saniye sonra  
                        break;
                    default:
                        console.error('Bilinmeyen hata: ' + result.message);
                        alert('Bilinmeyen bir hata oluştu.');
                }
            } else {  
                setShowToast(true);
                setToastMessage(result.message);
                if(result.status_code === 200){
               
                   setTimeout(() => handleGoBack(), 1000); // 2 saniye sonra 
                }
            }            
        } catch (error) {
            console.error("API hatası:", error);
            setShowToast(true);
            setToastMessage("API bağlantısı sağlanamadı.");
        }
    };

    const yagmurSuyuHesabi = (veri) => {
        if (veri > 0 && veri < 38.36) {
            return 'Ø50';
        } else if (veri >= 38.36 && veri < 78.31) {
            return 'Ø70';
        } else if (veri >= 78.31 && veri < 122.34) {
            return 'Ø100';
        } else if (veri >= 122.34 && veri < 175.75) {
            return 'Ø125';
        } else if (veri >= 175.75) {
            return 'Ø150';
        }
        return '';
    };

    return (
        <Container>
            <Card className="my-4">
                <Card.Body>
                    <Card.Title className="fw-bold">{maintainTransaction.project_name} / Yağmur Suyu Hesabı</Card.Title>
                </Card.Body>
            </Card>

            {/* Sarı arka planlı bilgi paneli */}
            <Alert variant="warning" className="text-start">
                {formData.a3 > 0 && formData.a5 ? (
                     <span><strong>{formData.a5}</strong> çapında <strong>{formData.a3}</strong> adet yağmur borusu seçilmiştir.</span>
                ) : (
                    <span>Henüz yağmur borusu seçimi yapılmadı.</span>
                )}
            </Alert>

            <Form onSubmit={handleSave}>
                <Form.Group controlId="a1">
                    <Form.Label className="fw-bold mb-2 text-capitalize">
                        P = Çatı alanı + balkon alanı{' '}
                        <span className="badge bg-dark text-white">m²</span>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        name="a1"
                        value={formData.a1}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="a2" className="mt-3">
                    <Form.Label className="fw-bold mb-2 text-capitalize">
                        S = Gerekli yağmur borusu alanı{' '}
                        <span className="badge bg-dark text-white">cm²</span>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        name="a2"
                        value={formData.a2}
                        onChange={handleChange}
                        disabled
                        required
                    />
                </Form.Group>

                <Form.Group controlId="a3" className="mt-3">
                    <Form.Label className="fw-bold mb-2 text-capitalize">
                        F = Kullanılan yağmur borusu adedi{' '}
                        <span className="badge bg-dark text-white">adet</span>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        name="a3"
                        value={formData.a3}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="a4" className="mt-3">
                    <Form.Label className="fw-bold mb-2 text-capitalize">
                        A = Yağmur borusu kesiti{' '}
                        <span className="badge bg-dark text-white">cm²</span>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        name="a4"
                        value={formData.a4}
                        onChange={handleChange}
                        disabled
                        required
                    />
                </Form.Group>

                <Form.Group controlId="a5" className="mt-3">
                    <Form.Label className="fw-bold mb-2 text-capitalize">
                        D = Boru çapı{' '}
                        <span className="badge bg-dark text-white">mm</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="a5"
                        value={formData.a5}
                        onChange={handleChange}
                        disabled
                        required
                    />
                </Form.Group>

                <div className="d-flex justify-content-start mt-4">
                    {/* a1 veya a3 doluysa Güncelle butonu, ikisi de boşsa Kaydet butonu */}
                    <Button variant="primary" className="me-2" type="submit">
                      {isUpdateMode ? 'Güncelle' : 'Kaydet'}
                    </Button>
                    <Button variant="secondary"   onClick={handleGoBack}>
                        Geri
                    </Button>
                </div>
            </Form>

            {/* Toast mesajı */}
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{ position: 'absolute', top: 20, right: 20 }}
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>


        </Container>
    );
};

export default YagmurSuyuHesabi;
