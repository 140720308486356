import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Collapse, Toast, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Myprojects = ({ setActiveTab, setEditingProject, setMaintainTransaction }) => {
  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState({
    project_name: '',
    responsible: '',
    building_name: '',
    device_no: '',
    start_date: '',
    end_date: ''
  });
  const [showFilters, setShowFilters] = useState(false); // Paneli açıp kapatma
  const [showToast, setShowToast] = useState(false); // Pop-up durumu
  const [toastMessage, setToastMessage] = useState(''); // Pop-up mesajı
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false); // Silme onayı için modal
  const [projectToDelete, setProjectToDelete] = useState(null); // Silinecek proje
  const navigate = useNavigate();
  const getProjectsUrl = process.env.REACT_APP_GET_PROJECTS_URL;
  const deleteProjectUrl = process.env.REACT_APP_DELETE_PROJECT_URL; // Proje silme API URL

  // İlk yüklemede son üç projeyi getir
  useEffect(() => {
    const fetchLastThreeProjects = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/signin');
        return;
      }

      try {
        const response = await fetch(`${getProjectsUrl}?son3=true`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 401) {
          navigate('/signin');
        } else if (!response.ok) {
          setError('Son üç proje alınırken bir hata oluştu.');
        } else {
          const data = await response.json();
          if (data.success) {
            setProjects(data.projects);
            setToastMessage('Son üç proje başarıyla yüklendi.');
            setShowToast(true);
            setError('');
          } else {
            setError('Son üç proje alınırken bir hata oluştu.');
          }
        }
      } catch (error) {
        setError('Son üç proje yüklenirken hata oluştu.');
      }
    };

    fetchLastThreeProjects();
  }, [navigate, getProjectsUrl]);

  // Filtre değişikliklerini yakalama
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  // Projeleri getirme fonksiyonu
  const fetchProjects = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/signin');
      return;
    }

    try {
      const queryParams = new URLSearchParams(filters).toString(); // Filtreleri query param olarak gönder
      const response = await fetch(`${getProjectsUrl}?${queryParams}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        navigate('/signin');
      } else if (!response.ok) {
        setError('Projeler alınırken bir hata oluştu.');
      } else {
        const data = await response.json();
        if (data.success) {
          setProjects(data.projects);
          setToastMessage('Projeler başarıyla yüklendi.');
          setShowToast(true);
          setError('');
        } else {
          setError('Projeler alınırken bir hata oluştu.');
        }
      }
    } catch (error) {
      setError('Projeler yüklenirken hata oluştu.');
    }
  };

  // Proje silme işlemi için onay modali açma
  const handleDeleteClick = (project) => {
    setProjectToDelete(project);
    setShowModal(true);
  };

  // Proje silme işlemi
  const handleDeleteConfirm = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(deleteProjectUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id: projectToDelete.id, action: 'delete' })
      });

      const data = await response.json();
      if (data.success) {
        setToastMessage('Proje başarıyla silindi.');
        setShowToast(true);
        setProjects(projects.filter(p => p.id !== projectToDelete.id)); // Listeden silinen projeyi çıkar
        setActiveTab('myprojects');
      } else {
        setToastMessage('Proje silinirken hata oluştu.');
        setShowToast(true);
      }
    } catch (error) {
      setToastMessage('Silme işlemi başarısız oldu.');
      setShowToast(true);
    }
    setShowModal(false);
  };

  // Proje düzenleme işlemi
  const handleEditClick = (project) => {
    setEditingProject(project); // Düzenlenecek projeyi Editproject'e gönder
    setActiveTab('editproject'); // Editproject tabına geçiş yap
  };

    // Proje işlemleri
    const handleTransactionClick = (project) => {
      setMaintainTransaction(project); // Düzenlenecek projeyi Editproject'e gönder
      setActiveTab('maintransaction'); // Editproject tabına geçiş yap
    };

  return (
    <Container>
      <Button
        onClick={() => setShowFilters(!showFilters)}
        aria-controls="filter-panel"
        aria-expanded={showFilters}
        variant="secondary"
        className="mb-3"
      >
        Seçim Ekranı Aç/Kapat
      </Button>
      
      <Collapse in={showFilters}>
        <div id="filter-panel">
          <Form>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="project_name">
                  <Form.Label>Proje Adı</Form.Label>
                  <Form.Control
                    type="text"
                    name="project_name"
                    value={filters.project_name}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="responsible">
                  <Form.Label>Sorumlusu</Form.Label>
                  <Form.Control
                    type="text"
                    name="responsible"
                    value={filters.responsible}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="building_name">
                  <Form.Label>Bina Adı</Form.Label>
                  <Form.Control
                    type="text"
                    name="building_name"
                    value={filters.building_name}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="device_no">
                  <Form.Label>Cihaz No</Form.Label>
                  <Form.Control
                    type="text"
                    name="device_no"
                    value={filters.device_no}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="start_date">
                  <Form.Label>Başlangıç Tarihi</Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={filters.start_date}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="end_date">
                  <Form.Label>Bitiş Tarihi</Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={filters.end_date}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" onClick={fetchProjects}>
              Araştır
            </Button>
          </Form>
        </div>
      </Collapse>

      {/* Proje listesi */}
      {error && <p className="text-danger mt-4">{error}</p>}
      {projects.length > 0 && !error && (
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Proje Adı</th>
              <th>Sorumlusu</th>
              <th>Bina Adı</th>
              <th>Cihaz No</th>
              <th>Tarih</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.id}>
                <td>{project.project_name}</td>
                <td>{project.responsible}</td>
                <td>{project.building_name}</td>
                <td>{project.device_no}</td>
                <td>{project.datum}</td>
                <td>
                  <Button variant="success" className="me-2" onClick={() => handleTransactionClick(project)}>
                    İşlemler
                  </Button>
                  <Button variant="warning" className="me-2" onClick={() => handleEditClick(project)}>
                    Değiştir
                  </Button>
                  <Button variant="danger" onClick={() => handleDeleteClick(project)}>
                    Sil
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Silme Onay Modali */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Proje Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bu projeyi silmek istediğinizden emin misiniz?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            İptal
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Sil
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast pop-up */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{ position: 'absolute', top: 20, right: 20 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default Myprojects;
