import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Toast } from 'react-bootstrap';

const Editproject = ({ editingProject, setActiveTab }) => {
  const [formData, setFormData] = useState({
    project_name: editingProject?.project_name || '',
    responsible: editingProject?.responsible || '',
    building_name: editingProject?.building_name || '',
    device_no: editingProject?.device_no || '',
    datum: editingProject?.datum ? editingProject.datum.slice(0, 10) : '' // Tarihi 'YYYY-MM-DD' formatına çevir
  });

  const [showToast, setShowToast] = useState(false); // Toast için state
  const [toastMessage, setToastMessage] = useState('');
  const updateProjectUrl = process.env.REACT_APP_UPDATE_PROJECT_URL;

  useEffect(() => {

    console.error(editingProject);
    // Eğer `editingProject`'te değişiklik olursa, form verisini güncelle
    setFormData({
      project_name: editingProject?.project_name || '',
      responsible: editingProject?.responsible || '',
      building_name: editingProject?.building_name || '',
      device_no: editingProject?.device_no || '',
      datum: editingProject?.datum ? editingProject.datum.slice(0, 10) : '' // Tarihi 'YYYY-MM-DD' formatına çevir
    });
  }, [editingProject]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(updateProjectUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          id: editingProject.id,
          ...formData,
          action: 'update' // Güncelleme işlemi için action ekledik
        })
      });

      const data = await response.json();
      if (data.success) {
        setToastMessage('Kayıt başarıyla güncellendi.');
        setShowToast(true);
        setTimeout(() => setActiveTab('myprojects'), 2000); // 2 saniye sonra Myprojects tabına geçiş
      } else {
        setToastMessage('Güncelleme başarısız oldu.');
        setShowToast(true);
      }
    } catch (error) {
      setToastMessage('Güncelleme işlemi başarısız.');
      setShowToast(true);
    }
  };

  const handleGoBack = () => {
    setActiveTab('myprojects'); // Listeye geri dönme işlemi
  };

  return (
    <Container>
      <h2 className="my-4">Projeyi Düzenle</h2>
      <Form onSubmit={handleSave}>
        <Form.Group controlId="project_name">
          <Form.Label>Proje Adı</Form.Label>
          <Form.Control
            type="text"
            name="project_name"
            value={formData.project_name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="responsible" className="mt-3">
          <Form.Label>Sorumlusu</Form.Label>
          <Form.Control
            type="text"
            name="responsible"
            value={formData.responsible}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="building_name" className="mt-3">
          <Form.Label>Bina Adı</Form.Label>
          <Form.Control
            type="text"
            name="building_name"
            value={formData.building_name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="device_no" className="mt-3">
          <Form.Label>Cihaz No</Form.Label>
          <Form.Control
            type="text"
            name="device_no"
            value={formData.device_no}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="datum" className="mt-3">
          <Form.Label>Tarih</Form.Label>
          <Form.Control
            type="date"
            name="datum"
            value={formData.datum}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <div className="d-flex justify-content-start mt-4">
          <Button variant="primary" type="submit" className="me-2">
            Kaydet
          </Button>
          <Button variant="secondary" onClick={handleGoBack}>
            Geri
          </Button>
        </div>
      </Form>

      {/* Toast mesajı */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{ position: 'absolute', top: 20, right: 20 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default Editproject;
