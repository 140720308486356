import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Newproject from '../pages/Newproject';
import Myprojects from '../pages/Myprojects';
import PendingApproval from '../pages/PendingApproval'; 
import Editproject from '../pages/Editproject'; // Editproject bileşeni eklendi
import Maintransaction from '../pages/transactions/main_transaction'; // Maintransaction bileşeni eklendi
import Yagmursuyuhesabi from '../pages/transactions/yagmur_suyu_hesabi'; // Maintransaction bileşeni eklendi
import { Navbar, Nav, NavDropdown, Alert } from 'react-bootstrap';
import { FaUserCircle, FaHome } from 'react-icons/fa';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [emailVerificationPending, setEmailVerificationPending] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // Admin kontrolü için state
  const [editingProject, setEditingProject] = useState(null); // Düzenlenecek proje
  const [maintainTransaction, setMaintainTransaction] = useState(null); // Düzenlenecek proje
  const [editingYagmurSuyuHesabi, setEditingYagmurSuyuHesabi] = useState(null); // Düzenlenecek proje

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_VALIDATE_URL;
  const verifyEmailUrl = process.env.REACT_APP_SEND_VERIFICATION_EMAIL_URL;  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/signin');
      return; 
    }

    const validateToken = async () => {
      try {
        const response = await fetch(`${apiUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();
        if (response.ok && data.status_code === 200) {
          if (data.email_verify_pending) {
            setEmailVerificationPending(true);
          }
          if (data.is_authorized) {
            setIsAdmin(true);
          }
        } else {
          localStorage.removeItem('token');
          navigate('/signin');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/signin');
      }
    };

    validateToken();
  }, [navigate, apiUrl]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/signin');
  };

  const handleEmailVerification = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${verifyEmailUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        alert("Email doğrulama talebiniz gönderildi.");
      } else {
        alert("Email doğrulama başarısız oldu.");
      }
    } catch (error) {
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  const handleProjectSuccess = () => {
    setActiveTab('myprojects'); 
  };

  return (
    <div className="w-100">
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-3">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Navbar.Brand href="/dashboard">
          <FaHome style={{  marginLeft: '1rem' }} /> 
        </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={() => setActiveTab('newproject')} disabled={emailVerificationPending}>
              Yeni Proje
            </Nav.Link>
            <Nav.Link onClick={() => setActiveTab('myprojects')} disabled={emailVerificationPending}>
              Projelerim
            </Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown title={<FaUserCircle style={{ fontSize: '1.5rem' }} />} id="user-nav-dropdown" align="end">
              {isAdmin && (
                <NavDropdown.Item onClick={() => setActiveTab('PendingApproval')}>
                  Onay Bekleyen Kullanıcılar
                </NavDropdown.Item>
              )}
              <NavDropdown.Item onClick={() => alert('Ayarlar sayfası!')}>Ayarlar</NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout}>Çıkış Yap</NavDropdown.Item> 
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="w-100">
        {activeTab === 'newproject' && <Newproject onSuccess={handleProjectSuccess} />}
        {activeTab === 'myprojects' && <Myprojects setActiveTab={setActiveTab} setEditingProject={setEditingProject} setMaintainTransaction={setMaintainTransaction}/>}
        {activeTab === 'PendingApproval' && <PendingApproval />}
        {activeTab === 'editproject' && <Editproject editingProject={editingProject} setActiveTab={setActiveTab} />}
        {activeTab === 'maintransaction' && <Maintransaction maintainTransaction={maintainTransaction} setActiveTab={setActiveTab} setEditingYagmurSuyuHesabi={setEditingYagmurSuyuHesabi} />}
        {activeTab === 'yagmursuyuhesabi' && <Yagmursuyuhesabi editingYagmurSuyuHesabi={editingYagmurSuyuHesabi} maintainTransaction={maintainTransaction} setActiveTab={setActiveTab} />}
        {!activeTab && (
          <div className="text-center">
            {emailVerificationPending ? (
              <Alert variant="warning" className="mx-auto" style={{ maxWidth: '600px' }}>
                <Alert.Heading>Email Adresi Doğrulaması Bekleniyor!</Alert.Heading>
                <p>
                  Email adresi henüz doğrulanmamış, doğrulamak için{' '}
                  <span onClick={handleEmailVerification} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
                    tıklayınız
                  </span>.
                </p>
              </Alert>
            ) : (
              <p>Lütfen bir seçenek seçin.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
